var Pagny = window.Pagny || {};

Pagny.common = {

  init: function() {
	// this.navToggle();
	// this.navSubnav();
	this.fixedHeader();
	this.navDropdownToggle();
	this.drawerToggle();
	// this.navDropdownHover();
	this.fullscreenMenuToggle();
	this.fullscreenMenuClose();
	this.searchToggle();
	this.accordionToggle();
	this.handleRemoveFilter();
	this.handleRemoveLetterFilter();
	this.stripStyles();
	// this.searchToggle();
	// this.visibilityToggle();
	// this.visibilityFilter();
	this.eventListeners();

	$('html').removeClass('no-js').addClass('js');

	// initialize modals
	$('[data-js="modal-entry"]').modaal({
	  type: 'ajax',
	  custom_class: 'modaal-fullscreen modal modal--page',
	  animation: 'fade',
	  overlay_opacity: 0.7,
	  loading_content: '<div class="sk-loader"><span class="sr-only">Loading</span></div>'
	});
	$('[data-js="modal-video"]').modaal({
		type: 'video',
		custom_class: 'modal-fullscreen modal modal--video',
		animation: 'fade',
		overlay_opacity: 0.7,
		loading_content: '<div class="sk-loader"><span class="sr-only">Loading</span></div>'
	});
	$('[data-js="modal-image"]').modaal({
		type: 'image',
		custom_class: 'modal-fullscreen modal modal--image',
		animation: 'fade',
		overlay_opacity: 0.7,
		loading_content: '<div class="sk-loader"><span class="sr-only">Loading</span></div>'
	});
  },
  
  stripStyles: function() {
	/* Strip style attributes */
	$tostrip = $('[data-js="strip-styles"]').find('[style]');
	$tostrip.removeAttr('style');
	/* Get rid of empty paragraphs */
	/* This doesn't work. Not sure why but it is occasionally removing paragraphs with content. */
	/*
	$p = $('[data-js="strip-styles"] p');
	$p.each(function(){
		if ($(this).children().length == 0){
			$(this).remove();
	 	}		
	});
	*/
  },
  
  navDropdownToggle: function() {
	$('.nav-dropdown-toggle').on('click', function(e) {
		e.preventDefault();
		var $toggle = $(this);
		var $dropdown = $toggle.siblings('.fullscreen-menu__nav-dropdown');
		$toggle.toggleClass('is-open');
		$dropdown.toggleClass('is-open');
	});
  },
  
  navDropdownHover: function() {
  	$('.header__nav > ul > li > a').on('hover', function(e) {
		  console.log("hovering");
		  var $toggle = $(this);
		  var $dropdown = $toggle.siblings('.header__nav-dropdown');
		  if ($dropdown.length) {
			  $toggle.toggleClass('is-open');
			  $dropdown.toggleClass('is-open');
		  }
	  });
  },
  
  drawerToggle: function() {
	$('.js-drawer-toggle').on('click', function(e) {
		e.preventDefault();
		console.log("toggling drawer");
		let $toggle = $(this);
		let $target = $($toggle.attr('data-target'));
		$toggle.toggleClass('is-open');
		$target.toggleClass('is-open');
	});  
  },

  fullscreenMenuToggle: function() {
	$('.fullscreen-menu-toggle').on('click', function(e) {
		e.preventDefault();
		var $toggle = $(this);
		var $menu = $('.header__fullscreen-menu');
		var $search = $('.fullscreen-menu__search input');

		$menu.toggleClass('is-open');
		$('html').toggleClass('is-fullscreen-menu-open');
		// If we're on desktop...
		// If we're opening the menu, give focus to the search...
		// If we're closing the menu, take focus away from the search if it has it
		if (window.matchMedia('(min-width: 1023px)').matches) {
			if ($menu.hasClass('is-open')) {
				$search.focus();
			} else {
				$search.blur();
			}
		}
	});
  },

  searchToggle: function() {
	$('.search-menu-toggle').on('click', function(e) {
		e.preventDefault();
		var $toggle = $(this);
		var $menu = $('.header__fullscreen-menu');
		var $search = $('.fullscreen-menu__search input');

		$menu.toggleClass('is-open');
		$('html').toggleClass('is-fullscreen-menu-open');
		// If we're on desktop...
		// If we're opening the menu, give focus to the search...
		// If we're closing the menu, take focus away from the search if it has it
		if (window.matchMedia('(min-width: 1023px)').matches) {
			if ($menu.hasClass('is-open')) {
				$search.focus();
			} else {
				$search.blur();
			}
		}
	});
  },
  
  fullscreenMenuClose: function() {
	$('.fullscreen-menu__close').on('click', function(e) {
		e.preventDefault();
		var $menu = $('.header__fullscreen-menu');
		var $h = $('html');
		if ($menu.hasClass('is-open')) {
			// console.log('menu is open, trying to close it');
			$menu.removeClass('is-open');
		}
		if ($h.hasClass('is-fullscreen-menu-open')) {
			// console.log('fullscreen menu open, trying to close it');
			$h.removeClass('is-fullscreen-menu-open');
		}
	});
  },

  accordionToggle: function() {
	$('.accordion__toggle').on('click', function(e) {
		e.preventDefault();
		var $toggle = $(this);
		var $target = $($toggle.attr('data-target'));
		$toggle.toggleClass('is-open');
		$target.toggleClass('is-open');
	});
  },

  handleRemoveFilter: function() {
    $('[data-js="remove"]').on('click', function(e) {
      e.preventDefault();

      let $elem = $(this);
      let $form = $($elem.attr('data-form'));
      let target = $elem.attr('data-target');

      $form.find(`[name=${target}]`)[0].value = '';
      $form.trigger('submit');
    });
  },
  
  handleRemoveLetterFilter: function() {
    $('[data-js="remove-letter"]').on('click', function(e) {
      e.preventDefault();

      let $elem = $(this);
      let $form = $($elem.attr('data-form'));
      let target = $elem.attr('data-target');
	  
	  $form.find(`[name=${target}]:checked`).removeAttr('checked');
	  $form.trigger('submit');	  
    });
  },

  /*
  visibilityToggle: function() {

	$('[data-toggle="visibility"]').on('click', function(e) {
	  e.preventDefault();

	  var $btn = $(this);
	  var $target = $($btn.attr('data-target'));
	  var state = $target.find('.is-visually-hidden').length ? 'hidden' : 'visible';
	  var originalText = 'Show more';

	  if($btn.data('originalText')) {
		originalText = $btn.data('originalText');
	  } else {
		$btn.data('originalText', $btn.text());
		originalText = $btn.data('originalText');
	  }

	  if(!$target.length) {
		return;
	  }

	  if(state === 'visible') {
		$target.find('.is-visually-shown').removeClass('is-visually-shown').addClass('is-visually-hidden');
		$btn.text(originalText).blur();
	  } else {
		$target.find('.is-visually-hidden').removeClass('is-visually-hidden').addClass('is-visually-shown');
		$btn.text('Show less').blur();
	  }
	});
  },
  */
  
  /*
  visibilityFilter: function() {

	$('[data-toggle="filter"]').on('change', function(e) {
	  e.preventDefault();

	  var $select = $(this);
	  var $target = $($select.attr('data-target'));
	  var val = $select.val();

	  if(!$target.length) {
		return;
	  }

	  if(!val) {
		$target.find('.is-visually-shown').removeClass('is-visually-shown').addClass('is-visually-hidden');
		$select.blur();
		return;
	  }

	  $target.find('.is-visually-shown').removeClass('is-visually-shown').addClass('is-visually-hidden');
	  $target.find('[data-id="'+val+'"]').removeClass('is-visually-hidden').addClass('is-visually-shown');
	  $select.blur();
	});
  },
  */
  
  /*
  searchToggle: function() {
	function escapeKey(e) {
	  if (e.keyCode === 27) {
		$('.header__search-toggle').trigger('click');
	  }
	}

	$('.header__search-toggle').on('click', function(e) {
	  $('html').toggleClass('is-search-open');

	  if(!$('html').hasClass('is-search-open')) {
		$('#site-search').attr('aria-expanded', 'false');
		$('.nav-backdrop').remove();
		$('#quicksearch')[0].blur();
		$(document).off('keyup', escapeKey);
	  } else {
		$(document.createElement('div'))
		  .addClass('nav-backdrop fade-in')
		  .appendTo($('body'))
		  .on('click', function(){
			$('.header__search-toggle').trigger('click');
		});
		$('#site-search').attr('aria-expanded', 'true');
		$('#quicksearch')[0].focus();
		$(document).on('keyup', escapeKey);
	  }
	});

	$('.header__search .close').on('click', function(e) {
	  $('.header__search-toggle').trigger('click');
	});
  },
  */
  
  /*
  navToggle: function() {
	$('.header__nav-toggle').on('click', function(e) {
	  var $toggle = $(this);
	  var $menu = $('.header__nav');

	  $menu.toggleClass('is-nav-active');
	  $('html').toggleClass('is-nav-open');

	  if(!$('html').hasClass('is-nav-open')) {
		$('.nav-backdrop').remove();
	  } else {
		$(document.createElement('div'))
		  .addClass('nav-backdrop fade-in')
		  .appendTo($('body'))
		  .on('click', function(){
			$toggle.trigger('click');
		});
	  }

	  $('html').removeClass('is-dropdown-open');

	  // reset subnav after closing transition
	  var timeout = setTimeout(function() {
		$('.header__nav-main li').removeClass('is-dropdown-active');
	  }, 500);
	});

	$('.header__nav .close').on('click', function(e) {
	  $('.header__nav-toggle').trigger('click');
	});

  },
  */
 
  navState: function() {

	// Only reset on desktop
	if(Pagny.common.isMobile()) {
	  return;
	}
	
	console.log("nav state resetting...");
	/*
	$('html').removeClass('is-nav-open');
	$('.nav-backdrop').remove();
	$('html').removeClass('is-dropdown-open');
	$('.header__nav').toggleClass('is-nav-active');
	$('.is-dropdown-active').toggleClass('is-dropdown-active');
	*/
  },

  fixedHeader: function() {

	// Header is only fixed on mobile
	if(!Pagny.common.isMobile()) {
	  $('html').removeClass('is-nav-fixed');
	  return;
	}

	var scrollPos = window.scrollY;
	var threshold = $('.site-header').height();

	if($('.hero').length) {
	  threshold = $('.hero').height();
	}

	$('html').toggleClass('is-nav-fixed', (scrollPos >= threshold));
  },

  eventListeners: function() {

	// Update on resize
	/*
	window.addEventListener('resize', function(e) {
	  if (!Pagny.ticking) {
		window.requestAnimationFrame(function() {
		  Pagny.common.navState();
		  Pagny.ticking = false;
		});

		Pagny.ticking = true;
	  }
	});
	*/

	// Update on scroll
	window.addEventListener('scroll', function(e) {
	  if (!Pagny.ticking) {
		window.requestAnimationFrame(function() {
		  Pagny.common.fixedHeader();
		  Pagny.ticking = false;
		});

		Pagny.ticking = true;
	  }
	});
  },

  isMobile: function() {
	return window.matchMedia('(max-width: 1023px)').matches;
  }

};

$(function() {
  Pagny.common.init();
})